img{
    max-width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: auto;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
.img-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
